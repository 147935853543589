import "./style.css";
import * as THREE from "three";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader.js";

import { RenderPass } from "three/examples/jsm/postprocessing/RenderPass.js";
import { EffectComposer } from "three/examples/jsm/postprocessing/EffectComposer.js";
import { UnrealBloomPass } from "three/examples/jsm/postprocessing/UnrealBloomPass.js";

import particleData from "./particleData.json";

const loader = new GLTFLoader();

const textureLoader = new THREE.TextureLoader();
const particleTexture = textureLoader.load("textures/particle.png");

const canvas = document.querySelector("canvas.webgl");

const scene = new THREE.Scene();

let light = new THREE.PointLight(0xffffff, 1);
light.position.set(0, 0, 1);
scene.add(light);

const sizes = {
  width: window.innerWidth,
  height: window.innerHeight,
};

const onResize = () => {
  // Update sizes
  sizes.width = window.innerWidth;
  sizes.height = window.innerHeight;

  // Update camera
  camera.aspect = sizes.width / sizes.height;
  camera.updateProjectionMatrix();

  // Update renderer
  renderer.setSize(sizes.width, sizes.height);
  renderer.setPixelRatio(window.devicePixelRatio);
  composer.setSize(sizes.width, sizes.height);
  composer.setPixelRatio(window.devicePixelRatio);
};
window.addEventListener("resize", onResize);

let particles, particleGeometry;
let originalPositions = [];

const addParticles = () => {
  particleGeometry = new THREE.BufferGeometry();
  const particleMaterial = new THREE.PointsMaterial({
    color: 0xffffff,
    size: 0.02,
    map: particleTexture,
    transparent: true,
    alphaTest: 0.5,
    opacity: 1,
  });

  particleGeometry.setAttribute(
    "position",
    new THREE.Float32BufferAttribute(particleData.positions, 3)
  );

  particleGeometry.setAttribute(
    "color",
    new THREE.Float32BufferAttribute(particleData.colors, 3)
  );

  particleMaterial.vertexColors = THREE.VertexColors;

  particles = new THREE.Points(particleGeometry, particleMaterial);
  scene.add(particles);
  originalPositions = particleData.positions.slice();
  initListenners();
};

let prevMouseX = 0;
let prevMouseY = 0;
let raycaster,
  mouse = new THREE.Vector2(),
  radius;

const initListenners = () => {
  raycaster = new THREE.Raycaster();
  radius = 0; // Inicjalna wartość promienia
  prevMouseX = 0;
  prevMouseY = 0;

  const updateRadius = (event) => {
    // Tutaj możesz zaktualizować promień na podstawie eventu lub innych kryteriów
    radius = 0.25; // Przykładowa aktualizacja promienia
  };

  const updateMousePosition = (event) => {
    if (!radius) {
      radius = 0.25;
    }
    mouse.x = (event.clientX / window.innerWidth) * 2 - 1;
    mouse.y = -(event.clientY / window.innerHeight) * 2 + 1;
  };

  canvas.addEventListener("pointermove", onMouseMove, {
    passive: true,
  });

  canvas.addEventListener("touchmove", onTouchMove, {
    passive: true,
  });

  function onMouseMove(event) {
    const events = event.getCoalescedEvents
      ? event.getCoalescedEvents()
      : [event];
    for (let i = 0; i < events.length; i++) {
      updateRadius(events[i]); // Zaktualizuj promień
      updateMousePosition(events[i]);
    }
  }

  function onTouchMove(event) {
    const touches = event.touches;
    for (let i = 0; i < touches.length; i++) {
      updateRadius(touches[i]); // Zaktualizuj promień
      updateMousePosition(touches[i]);
    }
  }
};

/**
 * Camera
 */

const camera = new THREE.PerspectiveCamera(
  75,
  sizes.width / sizes.height,
  0.1,
  100
);
camera.position.x = 0;
camera.position.y = 0;
camera.position.z = 2;
camera.lookAt(0, 0, 0);
scene.add(camera);

/**
 * Renderer
 */
const renderer = new THREE.WebGLRenderer({
  canvas: canvas,
  antialias: true,
});
renderer.setSize(sizes.width, sizes.height);
renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));

const renderScene = new RenderPass(scene, camera);

const composer = new EffectComposer(renderer);

composer.addPass(renderScene);

const bloomPass = new UnrealBloomPass(
  new THREE.Vector2(window.innerWidth, window.innerHeight),
  1,
  0.1,
  0.1
);
bloomPass.threshold = 0;
composer.addPass(bloomPass);

const clock = new THREE.Clock();

const bloomStrengthMultiplier = 1.5;

addParticles();

const tick = () => {
  if (particles) {
    particleGeometry.attributes.position.needsUpdate = true;
    const particlePosition = particles.geometry.attributes.position.array;
    let maxDistance = 0;

    for (let i = 0; i < particlePosition.length; i += 3) {
      const distance = Math.sqrt(
        Math.pow(particlePosition[i] - originalPositions[i], 2) +
          Math.pow(particlePosition[i + 1] - originalPositions[i + 1], 2) +
          Math.pow(particlePosition[i + 2] - originalPositions[i + 2], 2)
      );

      maxDistance = Math.max(maxDistance, distance);
    }

    bloomPass.threshold = maxDistance * 0.1;
    bloomPass.strength = maxDistance * bloomStrengthMultiplier;

    for (let i = 0; i < particlePosition.length; i++) {
      particlePosition[i] +=
        (originalPositions[i] - particlePosition[i]) * 0.05;
    }

    particles.geometry.attributes.position.needsUpdate = true;
  }
  for (let i = 0; i < 10; i++) {
    const deltaX = mouse.x - prevMouseX;
    const deltaY = mouse.y - prevMouseY;

    camera.position.x += deltaX * 0.03;
    camera.position.y += deltaY * 0.03;

    prevMouseX = mouse.x;
    prevMouseY = mouse.y;

    raycaster.setFromCamera(mouse, camera);

    const intersects = raycaster.intersectObject(particles);

    for (const intersect of intersects) {
      const particlePosition =
        intersect.object.geometry.attributes.position.array;
      const index = intersect.index * 3;

      const distance = Math.sqrt(
        Math.pow(particlePosition[index] - intersect.point.x, 2) +
          Math.pow(particlePosition[index + 1] - intersect.point.y, 2) +
          Math.pow(particlePosition[index + 2] - intersect.point.z, 2)
      );

      if (distance < radius) {
        particlePosition[index] +=
          (particlePosition[index] - intersect.point.x) * 0.05;
        particlePosition[index + 1] +=
          (particlePosition[index + 1] - intersect.point.y) * 0.05;
        particlePosition[index + 2] +=
          (particlePosition[index + 2] - intersect.point.z) * 0.05;
      }
    }
  }

  composer.render();

  if (window.innerWidth / window.innerHeight < 1000 / 750) {
    camera.fov = 75 * (1 + (1.25 - camera.aspect));
  } else {
    camera.fov = 75;
  }

  requestAnimationFrame(tick);
};

tick();
onResize();
